/* You can add global styles to this file, and also import other style files */

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
/* styles.css or styles.scss */
@import 'primeng/resources/primeng.min.css';


body {
    margin: 0px;
    padding: 0px;
    font-family: poppins;
   
}


